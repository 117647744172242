
import Swiper, {Pagination, Navigation} from "swiper";
class Investor {
    constructor() {
        this.shareholder = document.getElementsByClassName('toggle-list');
        this.sliderAR = document.querySelector('.sliderAR');
        this.filterFr = document.querySelector('.fr-filter');
        this.init();
    }
    init() {
        if (this.shareholder) {
            $('.accordion li .inner').hide();
            $('.accordion li:first .toggle-list').addClass('current');
            $('.accordion li:first .inner').addClass('show').show();
            $('.toggle-list').click(function(e) {
                e.preventDefault();
                let $this = $(this);
                if ($this.next().hasClass('show')) {
                    $this.next().removeClass('show');
                    $this.next().slideUp(350);
                    $('.toggle-list').removeClass('current');
                } else {
                    $('.toggle-list').removeClass('current');
                    $this.parent().parent().find('li .inner').removeClass('show');
                    $this.parent().parent().find('li .inner').slideUp(350);
                    $this.next().toggleClass('show');
                    $this.next().slideToggle(350);
                    $this.toggleClass('current');
                }
            });
        }
        if (this.sliderAR) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Navigation, Pagination]);

                let swiper = new Swiper('.sliderAR', {
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                    speed:1500,
                    autoplay: {
                        delay: 1500,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: ".next-preiewslider",
                        prevEl: ".prev-preiewslider",
                    },
                    pagination: {
                        el: ".ar-slidepagination",
                        clickable: true,
                    },
                    breakpoints: {
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                    },
                });
            });
        }
        if (this.filterFr) {
            $(document).on('click', '.fr-filter a', function(e){
                var $type = $(this).data("cat-source");
                $('.fr-filter a').removeClass('current');
                $(this).addClass('current');
                if($type == "all"){
                    $('.list-fr-cate').fadeOut(0);
                    $('.list-fr-cate').fadeIn(1000);
                }else{
                    $('.list-fr-cate:not([data-category="'+$type+'"])').hide();
                    $('.list-fr-cate[data-category="'+$type+'"]').fadeIn(1000);
                }
            })
        }
    }
}

export default Investor