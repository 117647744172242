
import Swiper, {Pagination, Navigation, Grid, FreeMode} from "swiper";
class Investor {
    constructor() {
        this.envManagement = document.querySelector('.env-management-slide');
        this.tabsBoard = document.querySelector('.tabsBoard');
        this.slidePaging = document.querySelector('.slide-paging');
        this.swipperGallery = document.querySelector('.swipperGallery');
        this.outerHei = document.querySelector('.outerHei');
        this.init();
    }
    init() {
        if (this.envManagement) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Navigation, Pagination]);

                let swiper = new Swiper('.env-management-slide', {
                    speed:1500,
                    navigation: {
                        nextEl: ".next-envslider",
                        prevEl: ".prev-envslider",
                    },
                });
            });
        }
        if(this.tabsBoard) {
            $('.detail-boards').hide();
            $('.detail-boards:first').show();
            $('.tabs-fr a').on('click', function(e) {
                e.preventDefault();
                var activeTab = $(this).attr('href');
                console.log(activeTab);
                $('.detail-boards').hide();
                $('.tabs-fr a').removeClass('current');
                $(this).addClass('current');
                $(activeTab).fadeIn();
            });
        }
        if (this.slidePaging) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Pagination]);

                let swiper = new Swiper('.slide-paging', {
                    speed:1500,
                    pagination: {
                        el: ".paging-slidepagination",
                        clickable: true,
                        renderBullet: function (index, className) {
                          return '<span class="' + className + '">' + (index + 1) + "</span>";
                        },
                      },
                });
            });
        }
        if (this.swipperGallery) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Pagination, Grid, FreeMode]);

                let swpr = new Swiper('.swipperGallery', {
                    slidesPerView: 3,
                    freeMode: true,
                    spaceBetween: 10,
                    grid: {
                        rows: 2,
                    },
                });
            });
        }
        if (this.outerHei) {
            document.addEventListener('DOMContentLoaded', () => {
                
                var currHeight = '400';
                setTimeout(() => {
                    if ($(window).width() > 768) {
                        currHeight  = $(".outerHei").outerHeight();
                    } else {
                        currHeight  = '400';
                    }
                    $(".area-h").css('height',currHeight+'px');
                }, 500);
                
                // var myImg = document.querySelector(".outerHei");
                // var currHeight = myImg.clientHeight;
                
                // document.querySelector(".area-h").style.height = currHeight+'px';
                    // var divs = document.getElementsByClassName('area-h');
                    // for(var i=0; i < divs.length; i++) { 
                    //     divs[i].style.height = currHeight;
                    // }

            });
        }
    }
}

export default Investor