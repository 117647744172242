import Swiper, {Pagination, Navigation, Thumbs} from "swiper";
class VisionMisionSlider {
    constructor() {
        this.visimisi = document.getElementsByClassName('vision-slider-wrapper');
        this.coreval = document.getElementsByClassName('slider-core-val');
        this.ourprod = document.getElementsByClassName('slider-ourpord');
        this.tabsOur = document.getElementById('search-nav-select');
        this.imgHover = document.getElementById('hovImg');
        this.init();
    }

    init() {
        if (this.visimisi) {
        document.addEventListener('DOMContentLoaded', () => {
            Swiper.use([Pagination, Navigation]);

            let swiper = new Swiper('.vision-slider-wrapper', {
                loop: true,
                pagination: {
                    el: ".vsms-slidepagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".next-vm",
                    prevEl: ".prev-vm",
                },
            });
        });
        }
        if (this.coreval) {
        document.addEventListener('DOMContentLoaded', () => {
            Swiper.use([Navigation, Pagination, Thumbs]);

            let swiperx = new Swiper(".slider-core-val", {
                slidesPerView: 4,
                // loop: true,
                freeMode: true,
                watchSlidesProgress: true,
                // navigation: {
                //   nextEl: ".swiper-button-next",
                //   prevEl: ".swiper-button-prev",
                // },
            });
            let swiper2 = new Swiper(".slider-detail-core-val", {
                spaceBetween: 10,
                thumbs: {
                swiper: swiperx,
                },
            });
        });
        }
        if (this.ourprod) {
        document.addEventListener('DOMContentLoaded', () => {
            Swiper.use([Navigation]);

            let swiper = new Swiper('.slider-ourpord', {
                loop: true,
                speed:1500,
                navigation: {
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
                },
            });
        });
        }
        if(this.tabsOur) {
            var pList = document.querySelector(".tab-select");
            for(var i=0; i<pList.length; i++) {
                if(pList[i] != div.children[0]) {
                    pList[i].style.display = "none";
                }
            }
            $('#search-nav-select').on('change', function() {
                var activeTab = $(this).val();
                $('.tab-select').hide();
                $('#'+activeTab).fadeIn();
            });
        }
        if(this.imgHover) {
            
            
        }
    }
}

export default VisionMisionSlider