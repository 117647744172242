import 'bootstrap';
import HomeSlider from './HomeSlider';
import VisiMisi from './VisionMisionSlider';
import InvestorJs from './Investor';
import EnvSlide from './Environment';
import GalleryModal from './Gallery';
let homeslider = new HomeSlider();
let slideVisimisi = new VisiMisi();
let investorJs = new InvestorJs();
let envSlideJS = new EnvSlide();
let modalGallery = new GalleryModal();
// let buttonScrollTo = document.querySelector('#scroll-to');
// buttonScrollTo.addEventListener('click', () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     });
// })

