
import SimpleLightbox from "simplelightbox";
class Gallery {
    constructor () {
        this.galleryPage = document.querySelector('.page-Gallery');
        this.init();
    }
    init () {
        if (this.galleryPage) {
            document.addEventListener('DOMContentLoaded', () => {
                new SimpleLightbox('.page-Gallery a', { /* options */ });
            });
        }
            
    }
}
export default Gallery