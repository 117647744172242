import Swiper, {Navigation, Pagination, EffectFade} from "swiper";
import $ from 'jquery';
import jqueryParoller from "paroller.js";
window.$ = window.jQuery = $;
import WOW from 'wowjs';
class HomeSlider {
    constructor () {
        this.slider = document.querySelector('.homeslider-swiper');
        this.paroller = document.querySelector('.paroler');
        this.slider2 = document.querySelector('.home-slidersustainability');
        this.slider3 = document.querySelector('.home-sliderAR');
        this.stick = document.querySelector('.topheader');
        this.tabVisiMisi = document.querySelector('.tabHome');
        this.init();
    }

    init () {
        let zeropos = 0;
        let ticking = false;
        function doSomething(scrollPos) {
            if(scrollPos > 200) {
                $('.topheader').addClass('sticky');
            } else {
                $('.topheader').removeClass('sticky');
            }
        }
        var $videoSrc;  
        $('.pop-about-vd').click(function() {
            $videoSrc = $(this).data( "src" );
        });
        $('#videoModal').on('shown.bs.modal', function (e) {
            $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
        })
        $('#videoModal').on('hide.bs.modal', function (e) {
            $("#video").attr('src',$videoSrc); 
        }); 
        document.addEventListener('scroll', function(e) {
            zeropos = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function() {
                    doSomething(zeropos);
                    ticking = false;
                });
                ticking = true;
            }
        });
        if (this.slider) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Navigation, Pagination,]);

                let swiper = new Swiper('.homeslider-swiper', {
                    loop: true,
                    speed:1200,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: ".custom-swiper-button-next",
                        prevEl: ".custom-swiper-button-prev",
                    },
                    pagination: {
                        el: ".home-slidepagination",
                        clickable: true,
                    },
                });
            });
        }
        if (this.paroller) {
            
            $('.paroler').click(function(){
            });
            new WOW.WOW({
                live: false
               //и любые другие параметры - это просто пример
            }).init();
        }
        if (this.slider2) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Navigation, Pagination]);

                let swiper = new Swiper('.home-slidersustainability', {
                    loop: true,
                    pagination: {
                        el: ".bullet-slidersustain",
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".next-slidersustainability",
                        prevEl: ".prev-slidersustainability",
                    },
                });
            });
        }
        if (this.slider3) {
            document.addEventListener('DOMContentLoaded', () => {
                Swiper.use([Navigation]);

                let swiper = new Swiper('.home-sliderAR', {
                    slidesPerView: 1,
                    speed:1500,
                    spaceBetween: 30,
                    autoplay: {
                        delay: 1500,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: ".next-preiewslider",
                        prevEl: ".prev-preiewslider",
                    }
                });
            });
        }
        
        if(this.tabVisiMisi) {
            // $('.detail-visimisi').hide();
            var pList = document.querySelector(".detail-visimisi");
            for(var i=0; i<pList.length; i++) {
                if(pList[i] != div.children[0]) {
                    pList[i].style.display = "none";
                }
            }
            $('.tabHome a').on('click', function(e) {
                e.preventDefault();
                var activeTab = $(this).attr('href');
                console.log(activeTab);
                $('.detail-visimisi').hide();
                $('.tabHome a').removeClass('current');
                $(this).addClass('current');
                $(activeTab).fadeIn();
            });
        }
    }
}

export default HomeSlider